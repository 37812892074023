import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
} from '@mui/material';
import * as XLSX from 'xlsx';
import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import PencilIcon from '../assets/edit.png';
import DeleteIcon from '../assets/trash.png';
import Iconify from 'src/components/iconify/Iconify';
import EyeIcon from '../assets/eye.png';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';
import AssistantAPI from '../components/company/AssistantAPI';
import ReactQuill from 'react-quill';
import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import OpenAI from 'openai';
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import { ConsoleNetwork } from 'mdi-material-ui';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'priority', label: 'Priority', alignRight: false },
  { id: 'englishQuestion', label: 'English Question', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false },
];
const TABLE_HEAD_EMPLOYEES = [
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'words', label: 'Words', alignRight: false },
  { id: 'tokens', label: 'Tokens', alignRight: false },
  { id: 'credits', label: 'Credits', alignRight: false },
  { id: 'packageExpireOn', label: 'Expired On', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

const excludeModules = ['video', 'image'];

const excludeFormats = ['video', 'image'];

export default function CompanyDetailsPage() {
  const openai = new OpenAI({
    apiKey: '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',
    dangerouslyAllowBrowser: true,
  });
  const [email, setEmail] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [company, setCompany] = useState({
    assistantFiles: [],
  });

  const { id } = useParams();
  const Font = Quill.import('formats/font');
  Font.whitelist = ['Arial', 'times', 'Calibiri', 'serif', 'monospace', 'san', 'Calligraffitti'];
  Quill.register(Font, true);
  const modules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }],
        [{ font: ['Arial', 'times', 'Calibiri', 'serif', 'monospace', 'san', 'Calligraffitti'] }],
        [{ size: [] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'code', 'formula'],
        ['clean'],
      ],
      handlers: {},
    },
    ...Object.fromEntries(excludeModules.map((module) => [module, false])),
  };
  Font.whitelist = ['mirza', 'roboto'];
  Quill.register(Font, true);

  var quill = new Quill('#editor-container', {
    modules: {
      toolbar: '#toolbar-container',
    },
    theme: 'snow',
  });

  const formats = [
    'header',
    'font',
    'size',
    'align',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'color',
    'background',
    'list',
    'bullet',
    'link',
    'code',
    'formula',
    // ...excludeFormats.map((format) => `-${format}`)
  ];

  const [info, setinfo] = useState({
    id: '',
    en_que: '',
    en_ans: '',
    fr_que: '',
    fr_ans: '',
    priority: 0,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [pageFaq, setPageFaq] = useState(0);
  const [rowsPerPageFaq, setRowsPerPageFaq] = useState(200);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [words, setWords] = useState(0);
  const [assistantFiles, setAssistantFiles] = useState([]);
  const [add, setAdd] = useState(false);
  const [assistant, setAssistant] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [editFaq, setEditFaq] = useState(false);
  const [addFaq, setAddFaq] = useState(false);
  const [isDisabeld, setIsDisabeld] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalEmployee, setDeleteModalEmployee] = useState(false);

  const handleCloseFaq = () => {
    setinfo({
      id: '',
      en_que: '',
      en_ans: '',
      fr_que: '',
      fr_ans: '',
      priority: 0,
    });
    setEditFaq(false);
    setAddFaq(false);
  };
  const handleClose = () => {
    setEditFaq(false);
    setAddFaq(false);
    setinfo({
      id: '',
      en_que: '',
      en_ans: '',
      fr_que: '',
      fr_ans: '',
      priority: 0,
    });
  };

  const handleCloseEmployee = () => {
    setEdit(false);
    setAdd(false);
    setEmail([]);
    setDataFile([]);
  };

  const handleCloseDelete = () => {
    setDeleteModal(false);
  };
  const handleAssistant = () => {
    setAssistant(false);
  };
  const handleCloseWords = () => {
    setEdit(false);
    setWords(0);
  };
  const handleCloseDeleteEmployee = () => {
    setDeleteModalEmployee(false);
  };
  const inputhandler = (event) => {
    const name = event.target.name;
    if (name == 'assistantFiles') {
      const value = event.target.files[0];
      setAssistantFiles([...assistantFiles, value]);
    } else {
      const value = event.target.value;
      setCompany({ ...company, [name]: value });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePageFaq = (event, newPage) => {
    setPageFaq(newPage);
  };

  const handleChangeRowsPerPageFaq = (event) => {
    setPageFaq(0);
    setRowsPerPageFaq(parseInt(event.target.value, 10));
  };
  const getAssistantFiles = async (assistant_id) => {
    console.log('assistant_id', assistant_id);

    const assistantFiles = await openai.beta.assistants.files.list(assistant_id);
    if (assistantFiles.body.data.length > 0) {
      setCompany({ ...company, assistantFiles: [...assistantFiles.body.data] });
    }
    // console.log('====================', assistantFiles);
    // for (let i = 0; i < assistantFiles.body.data.length; i++) {
    //   console.log('asad');
    //   let fileId = assistantFiles.body.data[i].id;
    //   console.log('==============================', fileId);
    //   const myAssistantFile = await openai.beta.assistants.files.retrieve(assistant_id, fileId);
    //   console.log('----------------------', myAssistantFile);
    // }
  };
  const getAllFaq = async () => {
    setLoader(true);
    await axiosApi('GET', `/faq/get-all-faq-by-company-id/${id}`)
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };

  const getCompanyDetails = async () => {
    setLoader(true);
    let companyData = {};
    try {
      const resp = await axiosApi('GET', `/company/get-company-by-id/${id}`);

      companyData = { ...resp.data };
      if (resp.data.assistantId) {
        const assistantFiles = await openai.beta.assistants.files.list(resp.data.assistantId);
        const knowledgeBase = assistantFiles.data.map((file) => file.id);
        let fileArray = [];
        for (const knowledge of knowledgeBase) {
          const fileData = await openai.files.retrieve(knowledge);
          fileArray.push({ name: fileData.filename, id: fileData.id });
        }
        companyData.assistantFiles = fileArray;
      }
      console.log('companyData=====', companyData);
      setCompany(companyData);
    } catch (err) {
      console.log(err);
    }
    setLoader(false);
  };
  const getAllEmployees = async () => {
    setLoader(true);
    await axiosApi('GET', `/company/get-all-company-employees/${id}`)
      .then((res) => {
        console.log(res);
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };

  const updateData = async () => {
    setIsDisabeld(true);
    await axiosApi('PUT', `/company/update-company-by-id/${info._id}`, info)
      .then((res) => {
        console.log(res);
        getAllFaq();
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
    setIsDisabeld(false);
  };
  const addWords = async () => {
    setIsDisabeld(true);
    await axiosApi('PUT', `/auth/update-tokens/${edit._id}`, { words: words, action: 'reset' })
      .then((res) => {
        console.log(res);
        getAllEmployees();
      })
      .catch((err) => {
        console.log(err);
      });
    handleCloseWords();
    setIsDisabeld(false);
  };
  const addData = async () => {
    setIsDisabeld(true);
    await axiosApi('POST', `/company/create-company-employee`, {
      email: email.length > 0 ? email : dataFile,
      companyId: id,
    })
      .then((res) => {
        console.log('res', res);
        getAllEmployees();
        handleCloseEmployee();
      })
      .catch((err) => {
        console.log('err', err);
      });
    handleClose();
    setIsDisabeld(false);
  };
  const deleteFaq = async () => {
    await axiosApi('DELETE', `/faq/delete-faq-by-id/${deleteModal}`)
      .then((res) => {
        getAllFaq();
      })
      .catch((err) => {
        console.log(err);
      });
    handleCloseDelete();
  };
  const sendFaq = async () => {
    const body = {
      en: { question: info.en_que, answer: info.en_ans },
      fr: { question: info.fr_que, answer: info.fr_ans },
      priority: info.priority,
      companyId: id,
    };
    if (addFaq) {
      await axiosApi('POST', `/faq/create-faq`, body)
        .then((res) => {
          setEdit(false);
          setinfo({
            id: '',
            en_que: '',
            en_ans: '',
            fr_que: '',
            fr_ans: '',
            priority: 0,
          });
          handleCloseFaq();
          getAllFaq();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const body = {
        en: { question: info.en_que, answer: info.en_ans },
        fr: { question: info.fr_que, answer: info.fr_ans },
        priority: info.priority,
        companyId: id,
      };
      await axiosApi('PUT', `/faq/update-faq-by-id/${info.id}`, body)
        .then((res) => {
          setEdit(false);
          setinfo({
            id: '',
            en_que: '',
            en_ans: '',
            fr_que: '',
            fr_ans: '',
            priority: 0,
          });
          getAllFaq();
          handleCloseFaq();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleChangeEnAns = (value) => {
    setinfo((prevState) => ({
      ...prevState,
      en_ans: value,
    }));
  };
  const handleChangeFrAns = (value) => {
    setinfo((prevState) => ({
      ...prevState,
      fr_ans: value,
    }));
  };
  const deleteEmployee = async () => {
    await axiosApi('DELETE', `/auth/delete-user-by-id/${deleteModalEmployee}`)
      .then((res) => {
        getAllEmployees();
      })
      .catch((err) => {
        console.log(err);
      });
    handleCloseDeleteEmployee();
  };
  const convertSheetToJson = (sheet) => {
    const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    const jsonData = [];
    for (let i = 0; i < rows.length; i++) {
      if (rows[i][0]) {
        jsonData.push(rows[i][0]);
      }
    }
    return jsonData;
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = convertSheetToJson(sheet);

      setDataFile(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    getAllFaq();
    getAllEmployees();
    getCompanyDetails();
  }, []);
  return (
    <>
      <Helmet>
        <title> Company Details </title>
      </Helmet>
      <Container>
        <Stack mb={5}>
          <Typography variant="h4" gutterBottom>
            Company Details
          </Typography>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '50%', border: '1px black solid' }}>
              {company ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      border: '1px black solid',
                      padding: '5px',
                    }}
                  >
                    <p>Name</p>
                    <p>{company?.name}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      border: '1px black solid',
                      padding: '5px',
                    }}
                  >
                    <p>Number</p>
                    <p>{company?.number}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      border: '1px black solid',
                      padding: '5px',
                    }}
                  >
                    <p>Expiration Date</p>
                    <p>{company?.expirationDate}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      border: '1px black solid',
                      padding: '5px',
                    }}
                  >
                    <p>Words Per Employee</p>
                    <p>{company?.wordsPerEmployee}</p>
                  </div>
                </>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '146px' }}>
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </Stack>
      </Container>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Employees
          </Typography>
          <Button
            onClick={() => {
              setAssistant(id);
            }}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {company?.assistantName ? 'Edit' : 'Add'} Assistant
          </Button>
          <Button
            onClick={() => {
              setAdd(true);
            }}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Employee
          </Button>
        </Stack>
        {loader ? (
          <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD_EMPLOYEES} rowCount={employees?.length} />

                  <TableBody>
                    {employees?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { email, tokens, packageExpireOn, words, firstname, assistantCredits } = row;
                      return (
                        <TableRow key={index}>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{firstname ? 'active' : 'pending'}</TableCell>
                          <TableCell align="left">{words}</TableCell>
                          <TableCell align="left">{tokens}</TableCell>
                          <TableCell align="left">{assistantCredits}</TableCell>
                          <TableCell align="left">
                            {packageExpireOn ? moment(packageExpireOn).format('LL') : 'Not Subscribed'}
                          </TableCell>
                          <TableCell style={{ display: 'flex' }} align="center">
                            <img
                              style={{ cursor: 'pointer', marginLeft: '15px' }}
                              src={PencilIcon}
                              onClick={() => {
                                setWords(row.words);
                                setEdit(row);
                              }}
                            ></img>
                            <img
                              style={{ cursor: 'pointer', marginLeft: '15px' }}
                              src={DeleteIcon}
                              onClick={() => {
                                setDeleteModalEmployee(row._id);
                              }}
                            ></img>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[200, 500, 1000]}
              component="div"
              count={employees?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}

        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5} mb={5}>
          <Typography variant="h4" gutterBottom>
            FAQ
          </Typography>
          <Button
            onClick={() => {
              setAddFaq(true);
            }}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add FAQ
          </Button>
        </Stack>
        {loader ? (
          <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={data.length} />
                  <TableBody>
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { _id, en, fr, priority } = row;
                      return (
                        <TableRow key={index}>
                          <TableCell align="left">{priority?.toString()}</TableCell>
                          <TableCell align="left">{en.question && parse(en.question)}</TableCell>
                          <TableCell style={{ display: 'flex' }} align="center">
                            <img
                              style={{ cursor: 'pointer' }}
                              src={PencilIcon}
                              onClick={() => {
                                setEditFaq(true);
                                setinfo({
                                  en_que: en.question,
                                  en_ans: en.answer,
                                  fr_que: fr.question,
                                  fr_ans: fr.answer,
                                  id: row._id,
                                  priority: row.priority,
                                });
                              }}
                            ></img>
                            <img
                              style={{ cursor: 'pointer', marginLeft: '15px' }}
                              src={DeleteIcon}
                              onClick={() => {
                                setDeleteModal(row._id);
                              }}
                            ></img>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[200, 500, 1000]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPageFaq}
              page={pageFaq}
              onPageChange={handleChangePageFaq}
              onRowsPerPageChange={handleChangeRowsPerPageFaq}
            />
          </Card>
        )}

        <Dialog
          open={add || info._id}
          onClose={handleCloseEmployee}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{add ? 'New Employee' : 'Update Employee Details'}</DialogTitle>
          <DialogContent style={{ minWidth: '600px' }}>
            <div>
              Email
              <TextField
                name="email"
                onChange={(e) => setEmail([e.target.value])}
                value={email}
                style={{ width: '100%' }}
              ></TextField>
            </div>
            <div>
              <div>
                <h3>Employee Emails</h3>
                <input type="file" onChange={handleFileUpload} />
                <pre>{JSON.stringify(dataFile, null, 2)}</pre>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            {add ? (
              <Button disabled={isDisabeld} onClick={() => addData()}>
                Add
              </Button>
            ) : (
              <Button disabled={isDisabeld} onClick={() => updateData()}>
                Update
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          open={edit}
          onClose={handleCloseWords}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Update Employee Words</DialogTitle>
          <DialogContent style={{ minWidth: '600px' }}>
            <div>
              Words
              <TextField
                type="number"
                name="words"
                onChange={(e) => setWords(Number(e.target.value))}
                value={words}
                style={{ width: '100%' }}
              ></TextField>
            </div>
          </DialogContent>
          <DialogActions>
            <Button disabled={isDisabeld} onClick={() => addWords()}>
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteModal}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Delete'}</DialogTitle>
          <DialogContent style={{ minWidth: '600px' }}>Are you sure you want to delete?</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                deleteFaq();
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                handleCloseDelete();
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteModalEmployee}
          onClose={handleCloseDeleteEmployee}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Delete'}</DialogTitle>
          <DialogContent style={{ minWidth: '600px' }}>Are you sure you want to delete?</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                deleteEmployee();
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                handleCloseDeleteEmployee();
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editFaq || addFaq}
          onClose={handleCloseFaq}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'FAQ Details'}</DialogTitle>
          <DialogContent style={{ minWidth: '600px', paddingBottom: '150px' }}>
            <div>
              <h4>Priority</h4>
              <TextField
                type="number"
                style={{ width: '100%' }}
                value={info?.priority}
                onChange={(e) => setinfo({ ...info, priority: e.target.value })}
              />
            </div>
            <div>
              <h4>Question (English)</h4>
              <ReactQuill
                value={info.en_que}
                onChange={(e) => setinfo({ ...info, en_que: e })}
                modules={modules}
                formats={formats}
              />
            </div>
            <div>
              <h4>Answer (English)</h4>
              <ReactQuill value={info.en_ans} onChange={handleChangeEnAns} modules={modules} formats={formats} />
            </div>
            <div>
              <h4>Question (French)</h4>
              <ReactQuill
                value={info.fr_que}
                onChange={(e) => setinfo({ ...info, fr_que: e })}
                modules={modules}
                formats={formats}
              />
            </div>
            <div>
              <h4>Answer (French)</h4>
              <ReactQuill value={info.fr_ans} onChange={handleChangeFrAns} modules={modules} formats={formats} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                sendFaq();
              }}
            >
              {addFaq ? 'Add' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      {company ? (
        <AssistantAPI
          setAssistantFiles={setAssistantFiles}
          assistantFiles={assistantFiles}
          getCompanyDetails={getCompanyDetails}
          handleAssistant={handleAssistant}
          assistant={assistant}
          company={company}
          inputhandler={inputhandler}
        />
      ) : null}
    </>
  );
}
