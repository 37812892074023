import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
//
import FeedbackPage from '../pages/FeedbackPage';
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/Page404';
import DashboardAppPage from '../pages/DashboardAppPage';
import FaqPage from '../pages/FaqPage';
import TokenPage from '../pages/TokenPage';
import PackagePage from '../pages/PackagePage';
import UserPage from '../pages/UserPage';
import PromptPage from '../pages/PromptPage';
import FooterPage from 'src/pages/FooterPage';
import HomePage from 'src/pages/HomePage';
import AboutPage from 'src/pages/AboutPage';
import PrivacyPage from 'src/pages/PrivacyPage';
import BlogPage from 'src/pages/BlogPage';
import CustomPage from 'src/pages/CustomPage';
import ChatPage from 'src/pages/ChatPage';
import CompanyPage from 'src/pages/CompanyPage';
import CompanyDetailsPage from 'src/pages/CompanyDetailsPage';
// ----------------------------------------------------------------------

export default function HomeRouter() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'feedback', element: <FeedbackPage /> },
        { path: 'faq', element: <FaqPage /> },
        { path: 'token', element: <TokenPage /> },
        { path: 'package', element: <PackagePage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'prompt', element: <PromptPage /> },
        { path: 'footer', element: <FooterPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'custom-package', element: <CustomPage /> },
        { path: 'home', element: <HomePage /> },
        { path: 'about', element: <AboutPage /> },
        { path: 'chat', element: <ChatPage /> },
        { path: 'company', element: <CompanyPage /> },
        { path: 'company/:id', element: <CompanyDetailsPage /> },
        { path: 'privacy', element: <PrivacyPage /> },


      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
