import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Chip,
  Grid,
} from '@mui/material';
import { axiosApi } from '../../../src/service/apiEnviornment';
import Button from '@mui/material/Button';
import { OpenAI } from 'openai';

import 'react-quill/dist/quill.snow.css';

export default function AssistantAPI({
  inputhandler,
  assistant,
  handleAssistant,
  company,
  getCompanyDetails,
  assistantFiles,
  setAssistantFiles,
}) {
  const openai = new OpenAI({
    apiKey: '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',
    dangerouslyAllowBrowser: true,
  });

  const [isDisabeld, setIsDisabeld] = useState(false);

  const handleClose = () => {
    handleAssistant();
  };

  async function uploadFile(file) {
    const textFile = new File([file], file.name, {
      type: file.type,
    });
    try {
      const assistantDetails = await openai.files.create({
        file: textFile,
        purpose: 'assistants',
      });
      return assistantDetails.id;
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
  const modifyAssistant = async () => {
    const myUpdatedAssistant = await openai.beta.assistants.update(company.assistantId, {
      instructions: company.assistantInstruction,
      name: company.assistantName,
      tools: [{ type: 'retrieval' }],
      model: 'gpt-4',
      file_ids: ['file-abc123', 'file-abc456'],
    });
  };
  const updateData = async () => {
    let tempFileIds = [];
    let payload = { ...company };
    for (const file of assistantFiles) {
      const fileId = await uploadFile(file);
      tempFileIds.push(fileId);
    }
    if (company?.assistantId) {
      const oldFiles = company?.assistantFiles.map((file) => file.id);
      await openai.beta.assistants.update(company.assistantId, {
        instructions: company.assistantInstruction,
        name: company.assistantName,
        file_ids: [...oldFiles, ...tempFileIds],
      });
    } else {
      const newAssistant = await openai.beta.assistants.create({
        instructions: company.assistantInstruction,
        name: company.assistantName,
        model: 'gpt-4-turbo-preview',
        tools: [{ type: 'retrieval' }],
        file_ids: tempFileIds,
      });
      payload.assistantId = newAssistant.id;
    }

    setIsDisabeld(true);
    await axiosApi('PUT', `/company/update-company-for-assistant/${assistant}`, payload)
      .then((res) => {
        console.log(res);
        getCompanyDetails();
        setAssistantFiles([]);
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
    setIsDisabeld(false);
  };

  return (
    <>
      <Container>
        <Dialog
          open={assistant}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Assistant'}</DialogTitle>
          <DialogContent style={{ minWidth: '600px' }}>
            <div>
              Assistant Instruction
              <TextField
                name="assistantInstruction"
                multiline={true}
                rows={4}
                onChange={inputhandler}
                value={company.assistantInstruction}
                style={{ width: '100%' }}
              ></TextField>
            </div>
            <div>
              Assistant Name
              <TextField
                name="assistantName"
                onChange={inputhandler}
                value={company.assistantName}
                style={{ width: '100%' }}
              ></TextField>
            </div>
            <div>
              Assistant File
              <TextField
                type="file"
                name="assistantFiles"
                onChange={inputhandler}
                style={{ width: '100%' }}
              ></TextField>
              {assistantFiles?.length > 0 &&
                assistantFiles?.map((file) => (
                  <Grid mt={1}>
                    <Chip
                      label={file.name}
                      variant="contained"
                      size="small"
                      onDelete={() => {
                        setAssistantFiles(assistantFiles.filter((obj) => obj.name !== file.name));
                      }}
                    />
                  </Grid>
                ))}
              {company?.assistantFiles?.length > 0 &&
                company?.assistantFiles?.map((file) => (
                  <Grid mt={1}>
                    <Chip label={file.name} variant="contained" size="small" color="primary" />
                  </Grid>
                ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isDisabeld}
              onClick={() => {
                updateData();
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
