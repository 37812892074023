import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { useAuth } from 'src/hooks/useAuth';
import { axiosApi } from 'src/service/apiEnviornment';
import parse from 'html-react-parser';


// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const { user } = useAuth()

  const [dashboardData, setDashboardData] = useState()
  const [userData, setUserData] = useState()
  const [dashboardLoader, setDashboardLoader] = useState(false)
  const [userLoader, setUserLoader] = useState(false)
  const [filter, setFilter] = useState("daily")
  const getDashboardData = async () => {
    setDashboardLoader(true)
    await axiosApi("GET", `/auth/get-dashboard`)
      .then((res) => {
        setDashboardData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setDashboardLoader(false)

  };
  const getUserData = async () => {
    setUserLoader(true)
    await axiosApi("GET", `/auth/no-of-users`)
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setUserLoader(false)

  };
  useEffect(() => {
    getDashboardData()
  }, [])
  useEffect(() => {
    getUserData()
  }, [filter])
  console.log(dashboardData)
  console.log("user",userData)

  return (
    <>
      <Helmet>
        <title> Dashboard | Jacques </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back {user?.email}
        </Typography>

        <Grid container spacing={3}>

          <Grid item xs={12} sm={6} md={3}>
           {userData?
           <AppWidgetSummary title="Daily" total={userData.daily.toString()}  />
           :null} 
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          {userData?
            <AppWidgetSummary title="Weekly" total={userData.weekly.toString()} color="info"  />
              :null} 
            </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {userData?
            <AppWidgetSummary title="Monthly" total={userData.monthly.toString()} color="warning"  />
              :null} 
            </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {userData?
            <AppWidgetSummary title="Yearly" total={userData.yearly.toString()} color="error"  />
              :null} 
            </Grid>



            <Grid item xs={12} md={6} lg={8}>
          {dashboardData?

            <AppConversionRates
              title="Top 10 consumers"
              subheader="Based on words usage"
              chartData={
                dashboardData.top10ConsumersInTimeFrame.map((item) => {
                  return {label:item.firstname + " " + item.lastname,value:item.burnWords}
                })
              }
           
            />
            :null}
          </Grid>
 


          {/* <Grid item xs={12} md={6} lg={8}>
          {dashboardData?

            <AppConversionRates
              title="Subscriptions"
              subheader="Based on subscribed users"
              chartData={
                dashboardData.usersBasedOnSubscription.map((item) => {
                  if(item._id.planName){
                    return {label:(item._id.planName)?"ASAD":"SAAD",value:item.count}
                  }
                })
              }
           
              
            />
            :null}
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
          {dashboardData?

            <AppConversionRates
              title="Subscriptions"
              subheader="Based on subscribed users"
              chartData={
                dashboardData.usersBasedOnSubscription.map((item) => {
                  const modified=parse(item._id.planName) + " " + parse(item._id.planType)
                  console.log(modified,typeof(modified))
                  return {label:modified,value:item.count}
                })
              }
           
            />
            :null}
          </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            {dashboardData?
                   <AppCurrentVisits
                   title="Total Users"
                   subheader={dashboardData.userDetails[0]?.totalUsers }
                   chartData={[
                     { label: 'Active', value:  dashboardData.userDetails[0]?.activeUsers },
                     { label: 'Non Active', value: dashboardData.userDetails[0]?.nonActiveUsers },
                   
                   ]}
                   chartColors={[
                     theme.palette.primary.main,
                     theme.palette.error.main,
                   ]}
                 />
            :null}
     
          </Grid>
{/* yahan dalna ha */}


<Grid item xs={12} md={12} lg={12}>
          {dashboardData?

            <AppConversionRates
              title="Subscriptions"
              subheader="Based on subscribed users"
              chartData={
                dashboardData.usersBasedOnSubscription.map((item) => {
          
                  return {label:(item._id.planName)?(item._id.planName + " " + ((item._id.planType=="monthly")?"M":"Y")):"NOT SUBSCRIBED",value:item.count}

                    // return {label:item._id.planName + " " + item._id.planType,value:item.count}
                })
              }
           

            />
            :null}
          </Grid>
          {/* <Grid item xs={12} md={6} lg={8}>
          {dashboardData?

            <AppConversionRates
              title="Top 10 consumers"
              subheader="Based on badges usage"
              chartData={
                dashboardData.top10ConsumersInTimeFrame.map((item) => {
                  return {label:item.firstname + " " + item.lastname,value:item.burnTokens}
                })
              }
           
            />
            :null}
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
