// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.png`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('analytics'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('user'),
  },
  {
    title: 'company',
    path: '/dashboard/company',
    icon: icon('company'),
  },
  {
    title: 'feedback',
    path: '/dashboard/feedback',
    icon: icon('feedback'),
  },
  {
    title: "Home",
    path: '/dashboard/home',
    icon: icon('home'),
  },
  {
    title: "Chat",
    path: '/dashboard/chat',
    icon: icon('chat'),
  },
 
  {
    title: 'prompt',
    path: '/dashboard/prompt',
    icon: icon('prompt'),
  },
  {
    title: 'token',
    path: '/dashboard/token',
    icon: icon('badge'),
  },
  {
    title: 'package',
    path: '/dashboard/package',
    icon: icon('package'),
  },
  {
    title: "Custom Package",
    path: '/dashboard/custom-package',
    icon: icon('package'),
  },
  
  {
    title: "Blog",
    path: '/dashboard/blog',
    icon: icon('blog'),
  },
  {
    title: "Footer",
    path: '/dashboard/footer',
    icon: icon('footer'),
  },
  {
    title: 'faq',
    path: '/dashboard/faq',
    icon: icon('faq'),
  },
  {
    title: "About",
    path: '/dashboard/about',
    icon: icon('about'),
  },
  {
    title: "Privacy",
    path: '/dashboard/privacy',
    icon: icon('privacy'),
  },

];

export default navConfig;
