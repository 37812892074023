import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Select,
  MenuItem,
} from '@mui/material';
import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import PencilIcon from '../assets/edit.png';
import DeleteIcon from '../assets/trash.png';
import parse from 'html-react-parser';

import moment from 'moment';
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'words', label: 'Words', alignRight: false },
  { id: 'tokens', label: 'Tokens', alignRight: false },
  { id: 'credits', label: 'Credits', alignRight: false },
  { id: 'packageExpireOn', label: 'Expired On', alignRight: false },
  { id: 'package', label: 'Package', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

export default function UserPage() {
  const [params, setParams] = useState({
    words: false,
    emailPrefix: '',
    expirationMonth: false,
  });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [data, setData] = useState([]);
  const [planAndExpiry, setPlanAndExpiry] = useState({
    userId: '',
    plan: ' ',
    packageExpireOn: '',
  });
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [info, setinfo] = useState({
    tokens: 0,
    words: 0,
    credits:0,
  });
  const [isDisabeld, setIsDisabeld] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [packageExpireOn, setPackageExpireOn] = useState('');

  const onPackageExpireChange = (e) => {
    setPackageExpireOn(e.target.value);
  };
  const handleCloseDelete = () => {
    setDeleteModal(false);
  };
  const handleCloseUpdateModal = () => {
    setUpdateModal(false);
  };
  const inputhandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinfo({ ...info, [name]: value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setEdit(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getData = async () => {
    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
    setLoader(true);
    await axiosApi('GET', `/auth/get-all-users?${queryString}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };
  const onUpdateExpiryDate = async () => {
    const userId = planAndExpiry.userId;
    const newExpirationDate = new Date(packageExpireOn);
    await axiosApi('POST', `/auth/update-expiry/${userId}`, { packageExpireOn: newExpirationDate })
      .then((res) => {
        if (res.data) {
          setUpdateModal(false);
          getData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onAssignFreePackage = async () => {
    const userId = planAndExpiry.userId;
    await axiosApi('POST', `/auth/assign-package/${userId}`)
      .then((res) => {
        if (res.data) {
          setUpdateModal(false);
          getData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (params.expirationMonth === '') {
      params.expirationMonth = false;
    }
    getData();
    setPage(0);
  }, [params]);
  const submit = async (action) => {
    setIsDisabeld(true);
    await axiosApi('PUT', `/auth/update-tokens/${edit._id}`, {
      ...info,
      action,
    })
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
    setinfo({
      tokens: 0,
      words: 0,
      credits:0,
    });
    setIsDisabeld(false);
  };
  const del = async () => {
    await axiosApi('DELETE', `/auth/delete-user-by-id/${deleteModal}`)
      .then((res) => {
        getData();
        handleCloseDelete();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Helmet>
        <title> Users </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <div style={{ display: 'flex', gap: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>Search By Email</Typography>
              <TextField
                placeholder="emailPrefix"
                value={params.emailPrefix}
                onChange={(e) => {
                  setParams({ ...params, emailPrefix: e.target.value });
                }}
              ></TextField>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>Search By Date</Typography>
              <TextField
                // onClick={() => setParams({ ...params, expirationMonth: false })}
                type="month"
                name="expirationDate"
                onChange={(e) => {
                  setParams({ ...params, expirationMonth: e.target.value });
                }}
                value={params.expirationMonth}
                style={{ width: '100%' }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
              <Typography>Sort By Words</Typography>
              <Select
                value={params.words}
                onChange={(e) => {
                  setParams({ ...params, words: e.target.value });
                }}
              >
                <MenuItem value={false}>Default</MenuItem>
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="des">Descending</MenuItem>
              </Select>
            </div>
          </div>
        </Stack>
        {loader ? (
          <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <TablePagination
                  rowsPerPageOptions={[200, 500, 1000]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={data.length} />

                  <TableBody>
                    {data
                      .filter((val) => {
                        if (search == '') {
                          return val;
                        } else if (val.email.toLowerCase().includes(search.toLowerCase())) {
                          return val;
                        }
                      })
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const { email, tokens, packageExpireOn, words, plan ,assistant} = row;
                        return (
                          <TableRow key={index}>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{words}</TableCell>
                            <TableCell align="left">{tokens}</TableCell>
                            <TableCell align="left">{assistant.packageCredits}</TableCell>
                            <TableCell align="left">
                              {packageExpireOn ? moment(packageExpireOn).format('LL') : 'Not Subscribed'}
                            </TableCell>
                            <TableCell align="left">{plan ? parse(plan.en) : 'No Active Plan'}</TableCell>

                            <TableCell style={{ display: 'flex' }} align="left">
                             
                              <img
                                style={{ cursor: 'pointer', marginLeft: '15px' }}
                                src={PencilIcon}
                                onClick={() => {
                                  setEdit(row);
                                  setinfo({words,tokens,credits:assistant?.packageCredits})
                                }}
                              ></img>
                              <img
                                style={{ cursor: 'pointer', marginLeft: '15px' }}
                                src={DeleteIcon}
                                onClick={() => {
                                  setDeleteModal(row._id);
                                }}
                              ></img>
                               {(row.plan == null || parse(row.plan.en) === 'Free' || !row.plan) && (
                                <Typography
                                  onClick={() => {
                                    setUpdateModal(true);
                                    const temData = {
                                      userId: row._id,
                                      plan: row.plan ? parse(row.plan.en) : null,
                                      packageExpireOn: row.packageExpireOn,
                                    };
                                    const date = new Date(row.packageExpireOn);
                                    setPackageExpireOn(format(date, 'yyyy-MM-dd'));
                                    setPlanAndExpiry({ ...temData });
                                  }}
                                  color={'primary'}
                                  sx={{ cursor: 'pointer' ,marginLeft:'10px'}}
                                >
                                  {row.plan == null || !row.plan ? 'Give plan' : 'Update'}
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        )}
        <Dialog
          open={edit}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Update Tokens'}</DialogTitle>
          <DialogContent style={{ minWidth: '600px' }}>
            <div>
              Tokens
              <TextField
                type="number"
                name="tokens"
                onChange={inputhandler}
                value={info.tokens}
                style={{ width: '100%' }}
              ></TextField>
            </div>
            <div>
              Words
              <TextField
                type="number"
                name="words"
                onChange={inputhandler}
                value={info.words}
                style={{ width: '100%' }}
              ></TextField>
            </div>
            <div>
              Credits
              <TextField
                type="number"
                name="credits"
                onChange={inputhandler}
                value={info.credits}
                style={{ width: '100%' }}
              ></TextField>
            </div>
          </DialogContent>
          <DialogActions>
            <Button disabled={isDisabeld} onClick={() => submit('add')}>
              Add
            </Button>

            <Button disabled={isDisabeld} onClick={() => submit('sub')}>
              Sub
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteModal}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Delete'}</DialogTitle>
          <DialogContent style={{ minWidth: '600px' }}>Are you sure you want to delete?</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                del();
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                handleCloseDelete();
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={updateModal} onClose={handleCloseUpdateModal}>
          {planAndExpiry.plan === null ? (
            <DialogTitle>{'Assign Free Plan'}</DialogTitle>
          ) : (
            <DialogTitle>{'Update Expiration Date'}</DialogTitle>
          )}

          <DialogContent style={{ minWidth: '600px' }}>
            {planAndExpiry.plan === null ? (
              <div>
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  color="primary"
                  sx={{ marginBottom: 2 }}
                  onClick={onAssignFreePackage}
                >
                  Give Free Plan
                </Button>
              </div>
            ) : (
              <div>
                <TextField
                  type="date"
                  size="small"
                  name="packageExpireOn"
                  onChange={onPackageExpireChange}
                  value={packageExpireOn}
                  style={{ width: '100%' }}
                ></TextField>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            {planAndExpiry.plan != null && (
              <Button size="small" variant="contained" onClick={onUpdateExpiryDate}>
                Update
              </Button>
            )}
            <Button size="small" variant="contained" onClick={() => setUpdateModal(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
