import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    DialogTitle,
    MenuItem,
    Select
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import PencilIcon from "../assets/edit.png"
import DeleteIcon from "../assets/trash.png"
import ReplyIcon from "../assets/reply.png"
import moment from "moment"
import Iconify from 'src/components/iconify/Iconify';
import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import ReactQuill from 'react-quill';
import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];


export default function CustomPage() {
    const [search,setSearch]=useState("")

    const Font = Quill.import("formats/font");
    Font.whitelist = ["Arial", "times", 'Calibiri', 'serif', 'monospace', 'san', 'Calligraffitti'];
    Quill.register(Font, true);

    const [loader, setLoader] = useState(false);
    const [loaderCustomPackage, setLoaderCustomPackage] = useState(false);
    const [customPackageData, setCustomPackageData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const [specificCustomPackageData, setSpecificCustomPackageData] = useState(
        {
            firstName: "",
            lastName: "",
            jobTitle: "",
            companyName: "",
            companyWebsite: "",
            businessEmail: "",
            numberOfEmployees: "",
            numberOfUsers: "",
            packageType: "",
            description: "",

        }
    )
    const [edit, setEdit] = useState(false)
    const [info, setinfo] = useState({})
    const [openModal, setOpenModal] = useState()
    const [deleteModal, setDeleteModal] = useState(false)
    const [replyModal, setReplyModal] = useState(false)
    const [replyMessage, setReplyMessage] = useState()

    const handleCloseReplyModal = () => {
        setReplyModal(false)
        setReplyMessage()
    };
    const handleCloseDelete = () => {
        setDeleteModal(false)
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const modules = {
        toolbar: {
            container: [
                [{ header: '1' }, { header: '2' }],
                [{ font: ['Arial', 'times', 'Calibiri', 'serif', 'monospace', 'san', 'Calligraffitti'] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ color: [] }, { background: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link', 'code', 'formula'],
                ['clean']
            ],
            handlers: {}
        },
        // ...Object.fromEntries(excludeModules.map((module) => [module, false])
        // )
    };
    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'color',
        'background',
        'list',
        'bullet',
        'link',
        'code',
        'formula',
        // ...excludeFormats.map((format) => `-${format}`
        // )
    ];

    const getData = async () => {
        setLoader(true)
        await axiosApi("GET", `/custom-page/get-custom-page-by-id/647dfb7da0ac6ee381d467e6`)
            .then((res) => {
                setinfo(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setLoader(false)

    };
    const getCustomPackage = async () => {
        setLoaderCustomPackage(true)
        await axiosApi("GET", `/custom/get-all-custom`)
            .then((res) => {
                setCustomPackageData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setLoaderCustomPackage(false)

    };

    const updateData = async () => {
        await axiosApi("PUT", `/custom-page/update-custom-page-by-id/647dfb7da0ac6ee381d467e6`, info)
            .then((res) => {
                getData()
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const updateCustomPackageData = async () => {
        console.log("slknslnasl", specificCustomPackageData)
        await axiosApi("PUT", `/custom/update-custom-by-id/${specificCustomPackageData._id}`, specificCustomPackageData)
            .then((res) => {
                getCustomPackage()
                handleClose()
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleClose = () => {
        setOpenModal(false)
        setSpecificCustomPackageData({
            firstName: "",
            lastName: "",
            jobTitle: "",
            companyName: "",
            companyWebsite: "",
            businessEmail: "",
            numberOfEmployees: "",
            numberOfUsers: "",
            packageType: "",
            description: "",

        })
    };




    const del = async () => {
        await axiosApi("DELETE", `/custom/delete-custom-by-id/${deleteModal}`)
            .then((res) => {
                getCustomPackage()
                handleCloseDelete()
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const updateStatus = async (id, body) => {
        await axiosApi("PUT", `/custom/update-custom-by-id/${id}`, body)
            .then((res) => {
                getCustomPackage()
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const sendEmail = async () => {
        const body = { email: replyModal, reply: replyMessage }
        await axiosApi("POST", `/custom/send-email-reply`, body)
            .then((res) => {
                // getCustomPackage()
            })
            .catch((err) => {
                console.log(err);
            });
        handleCloseReplyModal()
    }
    const inputhandler = (event) => {
        const name = event.target.name;
        const value = event.target.value
        setSpecificCustomPackageData({ ...specificCustomPackageData, [name]: value })
    }
    useEffect(() => {
        getData()
        getCustomPackage()
    }, [])
    return (
        <>
            <Helmet>
                <title> Custom Package  </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Custom Package
                    </Typography>
                    

                </Stack>
                {loader ? <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </div>
                    :
                    <Card>
                        <div style={{ padding: "20px" ,paddingBottom:"150px"}}>
                            <div>
                                <h4>Title (English)</h4>
                                <ReactQuill
                                    value={info?.title?.en}
                                    onChange={(e) => setinfo({ ...info, title: { ...info.title, en: e } })} modules={modules}
                                    formats={formats}
                                />
                                <h4>Title (French)</h4>
                                <ReactQuill
                                    value={info?.title?.fr}
                                    onChange={(e) => setinfo({ ...info, title: { ...info.title, fr: e } })} modules={modules}
                                    formats={formats}
                                />

                            </div>
                            <div>
                                <h4>Button (English)</h4>
                                <ReactQuill
                                    value={info?.button?.en}
                                    onChange={(e) => setinfo({ ...info, button: { ...info.button, en: e } })} modules={modules}
                                    formats={formats}
                                />
                                <h4>Button (French)</h4>
                                <ReactQuill
                                    value={info?.button?.fr}
                                    onChange={(e) => setinfo({ ...info, button: { ...info.button, fr: e } })} modules={modules}
                                    formats={formats}
                                />

                            </div>
                            <div>
                                <h4>Description (English)</h4>
                                <ReactQuill
                                    value={info?.description?.en}
                                    onChange={(e) => setinfo({ ...info, description: { ...info.description, en: e } })} modules={modules}
                                    formats={formats}
                                />
                                <h4>Description (French)</h4>
                                <ReactQuill
                                    value={info?.description?.fr}
                                    onChange={(e) => setinfo({ ...info, description: { ...info.description, fr: e } })} modules={modules}
                                    formats={formats}
                                />
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <Button onClick={() => { updateData() }}>Update</Button>
                            </div>


                        </div>
                    </Card>


                }
                <br></br>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Customers
                    </Typography>
                    <div>
            <TextField placeholder='Search' value={search} onChange={(e)=>{setSearch(e.target.value)}}></TextField>
          </div>


                </Stack>
                {loaderCustomPackage ? <div style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </div>
                    :
                    <Card>


                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={customPackageData.length}
                                    />

                                    <TableBody>
                                        {customPackageData.filter((val)=>{if(search==""){return(val)}
                                        else if(val.businessEmail.toLowerCase().includes(search.toLowerCase())){return (val)}})

                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            const { firstName, lastName, companyName, numberOfUsers, packageType, status,businessEmail } = row;

                                            return (
                                                <TableRow key={index}  >
                                                    <TableCell align="left">{firstName + " " + lastName}</TableCell>
                                                    <TableCell align="left">{businessEmail}</TableCell>
                                                    <TableCell align="left">{packageType}</TableCell>
                                                    <TableCell align="left">
                                                        <div onClick={() => {

                                                            if (status == true) {
                                                                updateStatus(row._id, { status: false });

                                                            }
                                                            else {
                                                                updateStatus(row._id, { status: true })
                                                            }
                                                        }
                                                        }

                                                            className={(status == true) ? "recievable-status-paid" : "recievable-status-unpaid"}>

                                                            <div className={(status == true) ? "paid-paid" : "paid-unpaid"}>
                                                                <p>RES</p>
                                                            </div>
                                                            <div className={(status == true) ? "unpaid-paid" : "unpaid-unpaid"}>
                                                                <p>NOT RES</p>
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell style={{ display: "flex" }} align="center">
                                                        <img style={{ cursor: "pointer", marginLeft: "15px" }} src={PencilIcon} onClick={() => { setEdit(true); setOpenModal(true); setSpecificCustomPackageData(row) }}></img>
                                                        <img style={{ cursor: "pointer", marginLeft: "15px" }} src={DeleteIcon} onClick={() => { setDeleteModal(row._id) }}></img>
                                                        <img style={{ cursor: "pointer", marginLeft: "15px" }} src={ReplyIcon} onClick={() => { setReplyModal(row.businessEmail) }}></img>

                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })}

                                    </TableBody>




                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[200, 500, 1000]}
                            component="div"
                            count={customPackageData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                }



            </Container>
            {openModal ?
                <Dialog
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Company Details"}
                    </DialogTitle>
                    <DialogContent style={{ minWidth: "600px" }}>
                        <div style={{ padding: "20px" }}>
                            <div>
                                <h4>First Name</h4>
                                <TextField style={{ width: "100%" }} value={specificCustomPackageData.firstName} onChange={inputhandler} name="firstName" />
                            </div>
                            <div>
                                <h4>Last Name</h4>
                                <TextField style={{ width: "100%" }} value={specificCustomPackageData.lastName} onChange={inputhandler} name="lastName" />
                            </div>
                            <div>
                                <h4>Job Title</h4>
                                <TextField style={{ width: "100%" }} value={specificCustomPackageData.jobTitle} onChange={inputhandler} name="jobTitle" />
                            </div>
                            <div>
                                <h4>Company Name</h4>
                                <TextField style={{ width: "100%" }} value={specificCustomPackageData.companyName} onChange={inputhandler} name="companyName" />
                            </div>
                            <div>
                                <h4>Company Website</h4>
                                <TextField style={{ width: "100%" }} value={specificCustomPackageData.companyWebsite} onChange={inputhandler} name="companyWebsite" />
                            </div>
                            <div>
                                <h4>Business Email</h4>
                                <TextField style={{ width: "100%" }} value={specificCustomPackageData.businessEmail} onChange={inputhandler} name="businessEmail" />
                            </div>
                            <div>
                                <h4>Number Of Employees</h4>
                                <TextField style={{ width: "100%" }} value={specificCustomPackageData.numberOfEmployees} onChange={inputhandler} name="numberOfEmployees" />
                            </div>
                            <div>
                                <h4>Number Of Users</h4>
                                <TextField style={{ width: "100%" }} value={specificCustomPackageData.numberOfUsers} onChange={inputhandler} name="numberOfUsers" />
                            </div>
                            <div>
                                <h4>Package Type</h4>
                                <Select style={{ width: "100%" }} value={specificCustomPackageData.packageType} onChange={inputhandler} name="packageType">
                                    <MenuItem value="monthly">Monthly</MenuItem>
                                    <MenuItem value="yearly">Yearly</MenuItem>

                                </Select>
                            </div>
                            <div>
                                <h4>Description</h4>
                                <TextField multiline={true} rows={4} style={{ width: "100%" }} value={specificCustomPackageData.description} onChange={inputhandler} name="description" />
                            </div>

                        </div>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => updateCustomPackageData()}>Update</Button>


                    </DialogActions>
                </Dialog>
                : null}
            <Dialog
                open={deleteModal}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete"}
                </DialogTitle>
                <DialogContent style={{ minWidth: "600px" }}>
                    Are you sure you want to delete?


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { del() }}>Yes</Button>
                    <Button onClick={() => { handleCloseDelete() }}>No</Button>

                </DialogActions>
            </Dialog>
            <Dialog
                open={replyModal}
                onClose={handleCloseReplyModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Reply"}
                </DialogTitle>
                <DialogContent style={{ minWidth: "600px" }}>
                    To : {replyModal}
                    <div>
                        <TextField style={{ width: "100%" }} multiline={true} rows={6} value={replyMessage} onChange={(e) => setReplyMessage(e.target.value)} />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { sendEmail() }}>Send</Button>

                </DialogActions>
            </Dialog>
        </>
    );
}
