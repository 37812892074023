import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
} from '@mui/material';
import { axiosApi } from 'src/service/apiEnviornment';
import Button from '@mui/material/Button';
import PencilIcon from '../assets/edit.png';
import DeleteIcon from '../assets/trash.png';
import Iconify from 'src/components/iconify/Iconify';
import EyeIcon from '../assets/eye.png';
import LinkIcon from '../assets/link.png';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// components

import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import { number } from 'prop-types';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'words', label: 'Words', alignRight: false },
  { id: 'assistantCredits', label: 'Assistant', alignRight: false },
  { id: 'number', label: 'Number', alignRight: false },
  { id: 'expirationDate', label: 'Expiration Date', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

export default function CompanyPage() {
  const companyUrl = 'https://company.jacques-assistant.com';
  const navigate = useNavigate();
  const [info, setinfo] = useState({
    name: '',
    email: '',
    number: '',
    wordsPerEmployee: '',
    expirationDate: '',
    assistantName: '',
    assistantInstruction: '',
    assistantId: '',
    assistantCreditsPerEmployee: '',
  });
  const [search, setSearch] = useState('');
  const [matchCase, setMatchCase] = useState({ wordsPerEmployee: '', assistantCreditsPerEmployee: '' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const handleClose = () => {
    setEdit(false);
    setAdd(false);
    setinfo({
      name: '',
      email: '',
      number: '',
      wordsPerEmployee: '',
      expirationDate: '',
      assistantName: '',
      assistantInstruction: '',
      assistantId: '',
      assistantCreditsPerEmployee: '',
    });
  };

  const [isDisabeld, setIsDisabeld] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleCloseDelete = () => {
    setDeleteModal(false);
  };
  const inputhandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setinfo({ ...info, [name]: value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const getData = async () => {
    setLoader(true);
    await axiosApi('GET', `/company/get-all-company`)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };

  const submit = async (action) => {
    setIsDisabeld(true);
    await axiosApi('PUT', `/company/update-tokens/${edit._id}`, {
      ...info,
      action,
    })
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();

    setIsDisabeld(false);
  };
  const updateData = async () => {
    setIsDisabeld(true);
    await axiosApi('PUT', `/company/update-company-by-id/${info._id}`, info)
      .then((res) => {
        console.log(res);
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
    setIsDisabeld(false);
  };
  const addWords = async () => {
    setIsDisabeld(true);
    const body = {};
    if (info.wordsPerEmployee != matchCase.wordsPerEmployee) {
      body.wordsPerEmployee = Number(info.wordsPerEmployee);
    }
    if (info.assistantCreditsPerEmployee != matchCase.assistantCreditsPerEmployee) {
      body.assistantCreditsPerEmployee = Number(info.assistantCreditsPerEmployee);
    }
    await axiosApi('PUT', `/company/update-employee-words-of-company/${info._id}`, body)
      .then((res) => {
        console.log(res);
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
    setIsDisabeld(false);
  };
  const addData = async () => {
    setIsDisabeld(true);
    await axiosApi('POST', `/company/create-company`, info)
      .then((res) => {
        console.log(res);
        // handleSuccess("Company added successfully")
        getData();
      })
      .catch((err) => {
        // handleFailure("Error in adding Company")
        console.log(err);
      });
    handleClose();
    setIsDisabeld(false);
  };
  const del = async () => {
    await axiosApi('DELETE', `/company/delete-company-by-id/${deleteModal}`)
      .then((res) => {
        getData();
        handleCloseDelete();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Helmet>
        <title> Company </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Company
          </Typography>
          <Button
            onClick={() => {
              setAdd(true);
            }}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Company
          </Button>
        </Stack>
        {loader ? (
          <div style={{ height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={data.length} />

                  <TableBody>
                    {data
                      .filter((val) => {
                        if (search == '') {
                          return val;
                        } else if (val.email.toLowerCase().includes(search.toLowerCase())) {
                          return val;
                        }
                      })
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const {
                          _id,
                          email,
                          name,
                          number,
                          expirationDate,
                          wordsPerEmployee,
                          assistantCreditsPerEmployee,
                        } = row;
                        return (
                          <TableRow key={index}>
                            <TableCell align="left">{name}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{wordsPerEmployee}</TableCell>
                            <TableCell align="left">{assistantCreditsPerEmployee}</TableCell>

                            <TableCell align="left">{number}</TableCell>
                            <TableCell align="left">
                              {expirationDate ? moment(expirationDate).format('LL') : 'Not Subscribed'}
                            </TableCell>
                            <TableCell style={{ display: 'flex' }} align="center">
                              <a href={`${companyUrl}/${row.slug}`} target="_blank">
                                <img style={{ cursor: 'pointer', marginLeft: '15px' }} src={LinkIcon}></img>
                              </a>
                              <img
                                style={{ cursor: 'pointer', marginLeft: '15px' }}
                                src={EyeIcon}
                                onClick={() => navigate(`/dashboard/company/${row._id}`)}
                              ></img>
                              <img
                                style={{ cursor: 'pointer', marginLeft: '15px' }}
                                src={PencilIcon}
                                onClick={() => {
                                  setinfo(row);
                                  setMatchCase({
                                    wordsPerEmployee: wordsPerEmployee,
                                    assistantCreditsPerEmployee: assistantCreditsPerEmployee,
                                  });
                                }}
                              ></img>
                              <img
                                style={{ cursor: 'pointer', marginLeft: '15px' }}
                                src={DeleteIcon}
                                onClick={() => {
                                  setDeleteModal(row._id);
                                }}
                              ></img>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[200, 500, 1000]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
        <Dialog
          open={add || info._id}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{add ? 'New Company' : 'Update Company'}</DialogTitle>
          <DialogContent style={{ minWidth: '600px' }}>
            <div>
              Name
              <TextField
                type="text"
                name="name"
                onChange={inputhandler}
                value={info.name}
                style={{ width: '100%' }}
              ></TextField>
            </div>
            <div>
              Email
              <TextField name="email" onChange={inputhandler} value={info.email} style={{ width: '100%' }}></TextField>
            </div>
            <div>
              Number
              <TextField
                type="number"
                name="number"
                onChange={inputhandler}
                value={info.number}
                style={{ width: '100%' }}
              ></TextField>
            </div>

            <div>
              Expiration Date
              <TextField
                type="date"
                name="expirationDate"
                onChange={inputhandler}
                value={info.expirationDate}
                style={{ width: '100%' }}
              ></TextField>
            </div>
            <div>
              Words
              <TextField
                type="number"
                name="wordsPerEmployee"
                onChange={inputhandler}
                value={info.wordsPerEmployee}
                style={{ width: '100%' }}
              ></TextField>
            </div>
            <div>
              Assistant Credits
              <TextField
                type="number"
                name="assistantCreditsPerEmployee"
                onChange={inputhandler}
                value={info.assistantCreditsPerEmployee}
                style={{ width: '100%' }}
              ></TextField>
            </div>
          </DialogContent>
          <DialogActions>
            {add ? (
              <Button disabled={isDisabeld} onClick={() => addData()}>
                Add
              </Button>
            ) : (
              <div>
                <Button disabled={isDisabeld} onClick={() => addWords()}>
                  Add
                </Button>
                <Button disabled={isDisabeld} onClick={() => updateData()}>
                  Update
                </Button>
              </div>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteModal}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Delete'}</DialogTitle>
          <DialogContent style={{ minWidth: '600px' }}>Are you sure you want to delete?</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                del();
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                handleCloseDelete();
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
